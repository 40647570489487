import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Title } from '../Title/Title';

import arrowDownIcon from '@/assets/svg/arrowdown-icon.svg';
import selectFromMenuWhiteIcon from '@/assets/svg/select-from-menu-white.svg';
import { IFiltersMenu, RecipeSort } from '@/interface/filter.interface';
import { ITitleProps } from '@/interface/title.interface';

interface IRecipesNavProps extends ITitleProps {
	filtersMenu: IFiltersMenu[];
	setSort: React.Dispatch<React.SetStateAction<RecipeSort>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RecipesNav = ({
	textTitle,
	colorText,
	titleWithColor,
	optionalText,
	filtersMenu,
	setSort,
	setOpen,
}: IRecipesNavProps) => {
	const [viewMenu, setViewMenu] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(location.search);
	const [filter, setFilter] = useState(() => {
		const sortFromUrl = params.get('sort');
		const filterFromSort =
			filtersMenu.find((f) => f.sort === sortFromUrl) || filtersMenu[0];
		return filterFromSort;
	});

	const updateQueryParams = (key: string, value: string) => {
		const currentParams = new URLSearchParams(location.search);
		if (value) {
			currentParams.set(key, value);
		} else {
			currentParams.delete(key);
		}
		navigate(`?${currentParams.toString()}`, { replace: true });
	};

	const onSelectFilter = (id: number, sort: RecipeSort) => {
		setFilter(filtersMenu[id]);
		setSort(sort);
		setViewMenu(false);
		updateQueryParams('sort', sort);
	};

	useEffect(() => {
		const sortFromUrl = params.get('sort');
		if (sortFromUrl) {
			const filterFromSort = filtersMenu.find((f) => f.sort === sortFromUrl);
			if (filterFromSort && filterFromSort.sort !== filter.sort) {
				const filterIndex = filtersMenu.findIndex(
					(f) => f.id === filterFromSort.id,
				);
				if (filterIndex !== -1) {
					onSelectFilter(filterIndex, filterFromSort.sort);
				}
			}
		}
	}, [location.search, filtersMenu, filter, onSelectFilter]);

	return (
		<nav className="flex flex-col items-start gap-y-5 md:flex-row md:justify-between ">
			<Title
				textTitle={textTitle}
				colorText={colorText}
				titleWithColor={titleWithColor}
				optionalText={optionalText}
			/>

			<div className="w-full flex flex-1 gap-x-4 justify-between sm:justify-end ">
				<div className="w-[45%] min-[430px]:w-[35%] sm:w-[180px]">
					<button
						data-cy="recipe-nav-sort-button"
						className="w-full flex justify-between items-center px-4 py-3 bg-slate-100 rounded-full"
						onClick={() => setViewMenu(!viewMenu)}
					>
						<div className="max-w-5xl  gap-2">
							<p className="text-[9px] leading-tight text-left">SORT BY</p>
							{
								<p className="text-sm leading-tight text-left font-semibold">
									{filter?.name}
								</p>
							}
						</div>
						<img
							src={arrowDownIcon}
							alt="filter-icon"
							className="w-6 h-6 sm:w-4 sm:h-4"
						/>
					</button>

					{viewMenu && (
						<div className="w-[44%] sm:w-[180px] mt-1 bg-slate-100 rounded-2xl shadow-lg opacity-100 z-20 absolute">
							{filtersMenu?.map((element) => (
								<button
									data-cy="recipe-nav-sort-item"
									key={element.id}
									className="block px-4 py-2 text-sm font-semibold  rounded-2xl hover:text-gray-500"
									onClick={() => onSelectFilter(element.id, element.sort)}
								>
									{element.name}
								</button>
							))}
						</div>
					)}
				</div>
				<button
					data-cy="recipe-nav-tag-button"
					className=" w-[45%] min-[430px]:w-[35%] sm:w-[180px] flex justify-between items-center py-4 px-4 text-white leading-tight bg-brandOrange rounded-full"
					onClick={() => setOpen(true)}
				>
					<div className="flex justify-between items-center gap-1 min-[330px]:gap-2 w-full h-full">
						<p className="text-sm font-semibold">All Filters</p>
						<img
							src={selectFromMenuWhiteIcon}
							alt="select from menu icon"
							className="ml-1 mt-[2px]"
						/>
					</div>
				</button>
			</div>
		</nav>
	);
};
