export const CreatorsFormLandingPage = () => {
	return (
		<div
			data-cy="creators-form-landing-page"
			className="max-w-[1536px] min-h-[80vh] mx-auto flex flex-col gap-y-6 md:gap-y-20 pt-12 lg:pt-0 lg:flex-row lg:items-center lg:justify-center"
		>
			<div className="lg:basis-1/2">
				<div className="mx-auto space-y-4 px-4 sm:px-10 lg:px-14 lg:w-[600px]">
					<h3 className="text-4xl sm:text-5xl lg:text-6xl pb-4 font-medium text-[#eb2426] tracking-widest w-full lg:text-justify">
						Want to be a Kitched creator?
					</h3>
					<p className="text-xl text-[#6a0136]">
						Grow with a community of creators and maximise the earnings from
						your digital content. Our Kitched technology makes it simple and
						easy.
					</p>
				</div>
			</div>
			<a
				href="mailto:hello@kitched.com"
				className="px-10 sm:grid sm:place-content-center sm:place-items-center text-white lg:basis-1/2 mb-6 lg:mb-0  "
			>
				<div className="space-y-4 bg-brandRedStrong hover:bg-red-700 rounded-xl shadow-md py-20 min-[440px]:px-10 ">
					<p className="text-2xl text-center sm:text-left min-[440px]:text-4xl text-white">
						Contact us at:
					</p>
					<p className="text-2xl text-center sm:text-left min-[440px]:text-4xl text-white">
						hello@kitched.com
					</p>
				</div>
			</a>
		</div>
	);
};
