import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { NavigationButtons } from '../common/buttons/navigation-button/NavigationButtons';
import { RecipeDinamicCardSkeletond } from '../common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '../common/recipe/recipe-card/RecipesCard';

import { Slider } from '@/components/common/slider/Slider';
import { useGetAllRandomRecipes } from '@/hooks/recipes/useGetAllRandomRecipes';
import { usePagination } from '@/hooks/usePagination';
import useWindowWidth from '@/hooks/useWindowWidth';

export const EasyDinnerRecipesSection = ({
	userHasSubscription,
}: {
	userHasSubscription?: boolean;
}) => {
	const [skip, setSkip] = useState(0);
	const [sliderStyle, setSliderStyle] = useState<string | null>(null);
	const take = 12;
	const windowWidth = useWindowWidth();
	const {
		allRandomRecipes,
		isLoadingAllRandomRecipes,
		isErrorAllRandomRecipes,
		refetchAllRandomRecipes,
		totalEntities,
	} = useGetAllRandomRecipes({ take, skip, tags: 'tag[]=easy&tag[]=dinner' });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	useEffect(() => {
		refetchAllRandomRecipes();
	}, [skip]);

	if (isErrorAllRandomRecipes) return <></>;

	return (
		<div data-cy="easy-dinner-section" className="w-full pt-5">
			<div className="flex flex-col gap-y-4 px-5">
				<h2 className="text-2xl sm:text-3xl md:text-4xl 2xl:text-5xl font-semibold">
					<span className="text-friendlyRed">Easy Dinner</span> Recipes
				</h2>
			</div>

			<div className="flex flex-col justify-center items-center lg:flex-row lg:items-center ">
				{isLoadingAllRandomRecipes ? (
					<div className="w-full lg:w-[88%] flex justify-center gap-2 mt-4 px-2">
						<RecipeDinamicCardSkeletond height="normal" />
						<RecipeDinamicCardSkeletond height="normal" />
						{windowWidth > 450 && (
							<RecipeDinamicCardSkeletond height="normal" />
						)}
						{windowWidth > 640 && (
							<RecipeDinamicCardSkeletond height="normal" />
						)}
					</div>
				) : (
					<div className="sm:w-full sm:pl-5" data-cy="easy-dinner-recipes">
						<Slider cardsType={{ type: 'SMALL' }}>
							{allRandomRecipes?.map((randomRecipe) => (
								<SwiperSlide
									key={randomRecipe.id}
									className={
										sliderStyle == randomRecipe.uuid
											? 'z-10 my-0 lg:mb-12 lg:mt-2'
											: 'z-0 my-0 lg:mb-12 lg:mt-2'
									}
								>
									<RecipesCard
										recipe={randomRecipe}
										setSliderStyle={setSliderStyle}
										userHasSubscription={userHasSubscription}
										isOnCarusel={true}
									/>
								</SwiperSlide>
							))}
						</Slider>
					</div>
				)}
				<div className="lg:-translate-y-20 sm:pr-5">
					<NavigationButtons
						currentPage={currentPage}
						totalPages={totalPages}
						handleNextClick={onHandleNext}
						handlePrevClick={onHandlePrevious}
					/>
				</div>
			</div>
		</div>
	);
};
