import { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Button } from './components/Button';

import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import useWindowWidth from '@/hooks/useWindowWidth';
import { IRecipe } from '@/interface/recipe.interfaces';

import searchPageContent from '@assets/image/landing-page-search-content.png';
import mealPlannerImage from '@assets/image/meal-planner-landing.png';

export const ContentDetailsLandingPage = () => {
	const [animationState, setAnimationState] = useState({
		first: false,
		second: false,
		third: false,
	});
	const windowWidth = useWindowWidth();

	const handleAfterAnimatedIn = (key: string) => {
		setAnimationState((prevState) => ({
			...prevState,
			[key]: true,
		}));
	};

	return (
		<div
			className="flex flex-col justify-center items-center gap-20 bg-gradient-to-b from-brandBeigeStrong to-white px-[5vw] xl:px-[10vw] py-[80px] "
			data-cy="kitched-details-section"
		>
			<div
				className="flex flex-col justify-start items-start  md:flex-row  md:justify-center md:items-center md:h-[700px] gap-16 "
				data-cy="kitched-details"
			>
				<ScrollAnimation
					className={`flex flex-col justify-between items-start gap-6 basis-1/2 lg:basis-[60%] order-1 md:order-2  ${
						animationState.third ? 'opacity-100' : 'opacity-0'
					}`}
					animateIn={
						windowWidth >= 768 ? 'animate-fadeInUp' : 'animate-fade-in-left'
					}
					animateOnce={true}
					afterAnimatedIn={() => {
						handleAfterAnimatedIn('third');
						return undefined;
					}}
				>
					<span className="text-brandRedStrong text-sm font-medium font-verdana">
						PLAN YOUR WEEK, CURATE COLLECTIONS, ADD DIETARY PREFERENCES
					</span>
					<h1 className="text-brandRedStrong text-[28px] min-[991px]:text-[45px]  font-semibold font-robotoMono md:leading-10 lg:leading-normal">
						PERSONALISED RECIPE RECOMMENDATIONS
					</h1>
					<Button text="See more" redirectTo="/signup" />
					<p className="font-verdana font-normal text-[20px] leading-5 text-[#6a0136] text-justify ">
						Start curating your profile today. Use a personalised meal planner,
						update your dietary preferences and create collections. Kitched
						saves you time when deciding what to cook.
					</p>
				</ScrollAnimation>
				<div className="flex justify-center  items-center md:justify-start basis-1/2 lg:basis-[40%] order-2 md:order-1 h-full w-full md:w-auto ">
					<div className="flex justify-center bg-brandRedStrong p-10 rounded-xl w-[80%] md:w-full md:h-full ">
						<img
							src={mealPlannerImage}
							alt=""
							className="w-auto h-full object-contain"
							data-cy="kitched-meal-image"
						/>
					</div>
				</div>
			</div>
			<div
				className="flex flex-col justify-start items-start  md:flex-row  md:justify-center md:items-center md:h-[500px] gap-16"
				data-cy="kitched-details"
			>
				<ScrollAnimation
					className={`flex flex-col justify-between items-start gap-6 basis-1/2 lg:basis-[60%]  ${
						animationState.second ? 'opacity-100' : 'opacity-0'
					}`}
					animateIn={
						windowWidth >= 768 ? 'animate-fadeInUp' : 'animate-fade-in-left'
					}
					animateOnce={true}
					afterAnimatedIn={() => {
						handleAfterAnimatedIn('second');
						return undefined;
					}}
				>
					<span className="text-brandRedStrong text-sm font-medium font-verdana">
						TRUSTWORTHY & FUNCTIONAL
					</span>
					<h1 className="text-brandRedStrong text-[28px] min-[991px]:text-[45px]  font-semibold font-robotoMono">
						TRUSTED RECIPE CONTENT
					</h1>
					<p className="font-verdana font-normal text-[20px] leading-5 text-[#6a0136]">
						We are a new type of recipe platform. Our recipes are directly from
						well known, trustworthy social media recipe creators. We make it
						easy to save, search and cook from content you love.
					</p>
					<Button text="See more" redirectTo="/signup" />
				</ScrollAnimation>
				<div className="flex justify-center  items-center md:justify-end basis-1/2 lg:basis-[40%]  w-full md:w-auto h-full">
					<div className="flex justify-center bg-brandRedStrong p-8 rounded-xl w-[80%] min-[500px]:w-[60%]  md:w-full md:h-full ">
						<RecipesCard
							recipe={landingRecipe as unknown as IRecipe}
							userHasSubscription={true}
							isLandingPage={true}
						/>
					</div>
				</div>
			</div>
			<div
				className="flex flex-col justify-start items-start  md:flex-row  md:justify-center md:items-center h-auto gap-16"
				data-cy="kitched-details"
			>
				<div className="flex justify-center items-center basis-1/2 lg:basis-[40%] h-full  order-2 md:order-1 w-full md:w-auto">
					<div className="flex justify-center bg-brandRedStrong p-4 md:p-10 rounded-xl w-[90%] md:w-full h-full md:h-full">
						<img
							src={searchPageContent}
							alt=""
							className="w-auto h-full object-cover"
						/>
					</div>
				</div>

				<ScrollAnimation
					className={`flex flex-col justify-between items-start gap-6 basis-1/2 lg:basis-[60%] order-1 md:order-2  ${
						animationState.first ? 'opacity-100' : 'opacity-0'
					}`}
					animateIn={
						windowWidth >= 768 ? 'animate-fadeInUp' : 'animate-fade-in-left'
					}
					animateOnce={true}
					afterAnimatedIn={() => {
						handleAfterAnimatedIn('first');
						return undefined;
					}}
				>
					<span className="text-brandRedStrong text-sm font-medium font-verdana">
						EASY SEARCH, SAVE AND FILTER
					</span>
					<h1 className="text-brandRedStrong text-[28px] min-[991px]:text-[45px]  font-semibold font-robotoMono">
						STOP SCROLLING. START COOKING.
					</h1>
					<p className="font-verdana font-normal text-[20px] leading-5 text-[#6a0136] text-justify">
						We specialise in recipe content and like you, we love reels,
						voiceovers and relatable creators. Our search, filter and save
						feature saves you time and endless scrolling.
					</p>
					<Button text="See more" redirectTo="/signup" />
				</ScrollAnimation>
			</div>
		</div>
	);
};

const landingRecipe = {
	id: 1171,
	uuid: 'c4432f1f-0106-4fbf-83f1-63a92b747205',
	title: 'Prawn Tacos with Corn and Avocado Salsa',
	tags: [
		{
			id: 5334,
			name: 'mexican',
		},
		{
			id: 5335,
			name: 'seafood',
		},
	],
	instagramInformation: {
		id: 1168,
		media_type: 'VIDEO',
		media_url:
			'https://s3-kitched-prod-ap-southeast-2.s3.ap-southeast-2.amazonaws.com/recipes/media/0fbf9231-83e7-4a3c-83df-632ac5148420.mp4',
		thumbnail_url:
			'https://cdn.kitched.com/kpQmcFD6mOfeIMmKY9cejOypg-8=/235x360/https://s3-kitched-prod-ap-southeast-2.s3.ap-southeast-2.amazonaws.com/thumbnail/bcfe3f2d-3a73-4bd3-a54d-7a961128310f.jpeg',
		permalink: 'https://www.instagram.com/reel/C-UWkHISmwp/',
	},
	likes: 0,
	saved: 0,
	chefUuid: '50b16fe0-3663-4358-be4f-e21153ecd3ad',
	chef: {
		id: 23,
		uuid: '50b16fe0-3663-4358-be4f-e21153ecd3ad',
		user: {
			id: 56,
			name: 'Kirrily',
			last_name: 'Edwards',
			profile_image: {
				src: 'https://cdn.kitched.com/JB7QKtot18BCz6mSa3XOJro8fgU=/300x500/https://s3-kitched-prod-ap-southeast-2.s3.ap-southeast-2.amazonaws.com/images/ab09c705-0891-47b3-926f-19cf32e8f45a.jpeg',
			},
		},
	},
};
