import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { getAllRandomRecipes } from '@/service/api/recipe';

export const useGetAllRandomRecipes = (options: IPagination) => {
	const {
		data: allRandomRecipes,
		isLoading: isLoadingAllRandomRecipes,
		isError: isErrorAllRandomRecipes,
		error: errorAllRandomRecipes,
		refetch: refetchAllRandomRecipes,
	} = useQuery({
		queryKey: ['all-random-recipes', options.skip],
		queryFn: () => getAllRandomRecipes(options),
		retry: false,
		staleTime: 10000,
	});

	return {
		allRandomRecipes: allRandomRecipes?.data ?? [],
		refetchAllRandomRecipes,
		isLoadingAllRandomRecipes,
		isErrorAllRandomRecipes,
		errorAllRandomRecipes,
		totalEntities: allRandomRecipes?.total as number,
	};
};
