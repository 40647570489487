import { createContext, useCallback, useEffect, useState } from 'react';

import { AuthContextProps, LoginData, User } from '@/context/interfaces';
import pendo from '@/interface/pendo';
import { SuscriberPlanEnum } from '@/interface/subscription.interfaces';
import { getUser } from '@/service/api/user';
import {
	authenticateUser,
	logoutUser,
	refreshUserToken,
} from '@/service/cognito/cognito.services';

export const AuthContext = createContext<AuthContextProps | undefined>(
	undefined,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);
	const userHasSubscription =
		!!user?.chef ||
		user?.subscription?.activePlan === SuscriberPlanEnum.ESSENTIAL ||
		user?.subscription?.activePlan === SuscriberPlanEnum.PRO;

	const queryParams = new URLSearchParams(window.location.search);
	const chef = queryParams.get('chef');
	const chefDecode = decodeURIComponent(chef ?? '');
	localStorage.setItem('chef', chefDecode);

	const fetchUser = useCallback(async () => {
		try {
			setLoading(true);
			const { session } = await refreshUserToken();
			if (!session) return;
			const user = await getUser(session.getAccessToken().getJwtToken());
			setUser(user);
			if (user) {
				pendo.identify(user.id.toString());
			}
		} catch (error) {
			console.error('Error fetching user', error);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchUser();
	}, []);

	const login = async (loginData: LoginData): Promise<void> => {
		const user = await authenticateUser(loginData);
		if (user) setUser(user);
		if (user) {
			pendo.identify(user.id.toString());
		}
	};

	const logout = () => {
		logoutUser();
		setUser(null);
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				login,
				logout,
				setUser,
				loading,
				fetchUser,
				userHasSubscription,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
