import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { trendingRecipesFiltersMenu } from '@/assets/mock/date_filter_items.mock';
import { Loader } from '@/components/common/Loader';
import { GoBackButtons } from '@/components/common/buttons/GoBackButtons';
import { TagsSearchMenuPopUp } from '@/components/common/pop-up/TagsPopUp';
import { RecipesNav } from '@/components/common/recipe/RecipesNav';
import { TrendingGridComponent } from '@/components/common/recipe/masonry-recipe/TrendingGridComponent';
import { TrendingChefsSection } from '@/components/home/TrendingChefsSection';
import { useAuth } from '@/context/useAuth';
import { useGetMostLikedRecipes } from '@/hooks/recipes/useGetMostLikedRecipes';
import { RecipeSort } from '@/interface/filter.interface';
import { IRecipe } from '@/interface/recipe.interfaces';

export const ExploreRecipes = () => {
	const { userHasSubscription } = useAuth();
	const [recipes, setRecipes] = useState<IRecipe[]>([]);
	const [skip, setSkip] = useState(0);
	const [sort, setSort] = useState(RecipeSort.LATEST);
	const [tags, setTags] = useState<string>('');
	const take = 18;
	const [hasMore, setHasMore] = useState(true);
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);

	const {
		mostLikedRecipes,
		isErrorMostLikedRecipes,
		errorMostLikedRecipes,
		isLoadingMostLikedRecipes,
		refetchMostLikedRecipes,
		isRefetchingMostLikedRecipes,
	} = useGetMostLikedRecipes({ take, skip, sort, tags });

	const onHandleSearchTag = (tag: string) => {
		setTags(tag);
	};

	const fetchMoreData = () => {
		if (!isLoadingMostLikedRecipes && hasMore) {
			setSkip((prevSkip) => prevSkip + take);
		}
	};

	useEffect(() => {
		setRecipes([]);
		setSkip(0);
		setHasMore(true);
	}, [sort, tags]);

	useEffect(() => {
		refetchMostLikedRecipes();
	}, [sort, tags, skip]);

	useEffect(() => {
		if (mostLikedRecipes) {
			if (skip === 0) {
				setRecipes(mostLikedRecipes.data);
			} else {
				setRecipes((prevRecipes) => [...prevRecipes, ...mostLikedRecipes.data]);
			}
			setHasMore(mostLikedRecipes.data.length > 0);
		}
	}, [mostLikedRecipes]);

	if (isLoadingMostLikedRecipes && recipes.length === 0) {
		return (
			<div className="flex justify-center items-center">
				<Loader size={4} />
			</div>
		);
	}

	if (isErrorMostLikedRecipes) {
		return (
			<div
				className="flex mt-20 justify-center items-center  flex-col w-full   gap-6"
				data-cy="explore-recipes-error"
			>
				<p className="text-black text-3xl sm:text-4xl text-center font-sans font-bold tracking-tighter w-[50%]">
					{errorMostLikedRecipes?.message}
				</p>
			</div>
		);
	}

	const renderRecipeGrid = () => {
		const grids = [];
		for (let i = 0; i < recipes.length; i += 18) {
			grids.push(
				<TrendingGridComponent
					key={i}
					recipes={recipes.slice(i, i + 18)}
					itemsCenter={i > 0}
					columnReverse={i % 2 === 0}
					isExploreRecipesPage
					userHasSubscription={userHasSubscription}
					isRefetchingMostLikedRecipes={isRefetchingMostLikedRecipes}
				/>,
			);
		}
		return grids;
	};

	return (
		<div data-cy="infinite-scroll-container">
			<div className="flex px-[18px] sm:px-6 mt-6 gap-2 mb-2">
				<GoBackButtons />
			</div>

			<TrendingChefsSection />

			<div>
				<div className="w-full px-5 my-8">
					<RecipesNav
						textTitle="Trending"
						titleWithColor="on Kitched"
						colorText="brandRed"
						filtersMenu={trendingRecipesFiltersMenu}
						setSort={setSort}
						setOpen={setOpen}
					/>
				</div>
				<InfiniteScroll
					dataLength={recipes.length}
					next={fetchMoreData}
					hasMore={hasMore}
					loader={
						<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl">
							Loading more recipes...
						</p>
					}
					endMessage={
						<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl font-helveticaNeue">
							No more recipes
						</p>
					}
				>
					<section className="flex flex-wrap justify-center mt-10 mb-10  ">
						<div
							data-cy="explore-recipes-cards"
							className="w-full flex flex-wrap justify-center"
						>
							{renderRecipeGrid()}
						</div>
					</section>
				</InfiniteScroll>
			</div>
			<TagsSearchMenuPopUp
				onHandleSearchTag={onHandleSearchTag}
				open={open}
				onCloseModal={onCloseModal}
				sectionName={'trend'}
			/>
		</div>
	);
};
