import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import { Loader } from '@/components/common/Loader';
import { Title } from '@/components/common/Title/Title';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { Slider } from '@/components/common/slider/Slider';
import { useGetRecipesByUserPreferences } from '@/hooks/recipes/useGetRecipesByUserPreferences';
import { usePagination } from '@/hooks/usePagination';

interface IRecipesForYouByPreferenceProps {
	userHasSubscription?: boolean;
}

export const RecipesForYouByPreferences = ({
	userHasSubscription,
}: IRecipesForYouByPreferenceProps) => {
	const [skip, setSkip] = useState(0);
	const [sliderStyle, setSliderStyle] = useState<string | null>(null);

	const take = 18;
	const {
		userPreferenceRecipes,
		totalEntities,
		isLoadingUserPreferenceRecipes,
		isErrorUserPreferenceRecipes,
		refetchUserPreferenceRecipes,
	} = useGetRecipesByUserPreferences({ take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	useEffect(() => {
		refetchUserPreferenceRecipes();
	}, [skip]);

	if (isLoadingUserPreferenceRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (isErrorUserPreferenceRecipes) return <></>;

	return (
		<div data-cy="recipesforyou-section" className="w-full">
			<div className="flex  items-center gap-x-4 px-5 ">
				<Title
					textTitle="Recipes"
					colorText="brandRed"
					titleWithColor="for you"
				/>
				<Link to={`/settings/me`} data-cy="recipeforyou-configure-button">
					<button className="relative hidden md:flex py-3 px-4 bg-brandOrange hover:bg-[#E1B864] rounded-full text-white font-semibold transition-all :text-brandOrange :bg-white :ring-2 :ring-brandOrange :cursor-pointer font-helveticaNeue tracking-[0.02em]">
						<span>Configure</span>
						<img src={arrowUpRight} alt="arrow-up-right-icon" />
					</button>
				</Link>
			</div>

			{totalEntities === 0 ? (
				<div className="px-8 mt-6 ">
					<RecipeDinamicCardSkeletond
						height="normal"
						message="No recipes found"
					/>
				</div>
			) : (
				<div className="flex flex-col justify-center items-center lg:flex-row lg:items-center lg:gap-x-4">
					<Slider cardsType={{ type: 'SMALL' }}>
						{userPreferenceRecipes?.map((recipes) => (
							<SwiperSlide
								key={recipes.id}
								className={
									sliderStyle == recipes.uuid
										? 'z-10 my-0 lg:mb-12 lg:mt-2'
										: 'z-0 my-0 lg:mb-12 lg:mt-2'
								}
							>
								<RecipesCard
									recipe={recipes}
									setSliderStyle={setSliderStyle}
									userHasSubscription={userHasSubscription}
									isOnCarusel={true}
								/>
							</SwiperSlide>
						))}
					</Slider>
					<div className="lg:-translate-y-20">
						<NavigationButtons
							currentPage={currentPage}
							totalPages={totalPages}
							handleNextClick={onHandleNext}
							handlePrevClick={onHandlePrevious}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
