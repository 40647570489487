import { EventInput } from '@fullcalendar/core';

import { MealPlan } from '@/interface/mealPlan.interfaces';

export const generateUniqueEventId = () => {
	const uuid = Math.random().toString() + Math.random().toString();
	return uuid;
};

export const getDate = () => {
	const today = new Date();

	const startDate = new Date(today);
	startDate.setDate(today.getDate() - 1);

	const endDate = new Date(today);
	endDate.setDate(today.getDate() + 7);
	const dates = [];
	const currentDate = new Date(today);

	while (currentDate <= endDate) {
		dates.push(new Date(currentDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return { today, startDate, endDate, dates };
};

export const generatePlaceholderEvents = (events: MealPlan[]) => {
	const { today, endDate } = getDate();

	const placeholderEvents: EventInput[] = [];

	for (let d = today; d <= endDate; d.setDate(d.getDate() + 1)) {
		const dateStr = d.toISOString().split('T')[0];
		const eventsForDay = events.filter((event) => {
			if (typeof event.date === 'string') {
				return event.date.startsWith(dateStr);
			}
			return false;
		});

		const totalEvents = eventsForDay.length;

		if (totalEvents < 4) {
			for (let i = totalEvents; i < 4; i++) {
				placeholderEvents.push({
					id: generateUniqueEventId(),
					title: '',
					start: dateStr,
				});
			}
		}
	}

	return placeholderEvents;
};

interface fetchMealPlanDataProps {
	mealPlans?: MealPlan[];
	setEvents: React.Dispatch<React.SetStateAction<EventInput[]>>;
	refetch: () => void;
}

export const fetchMealPlanData = ({
	mealPlans,
	setEvents,
	refetch,
}: fetchMealPlanDataProps) => {
	if (mealPlans && mealPlans?.length > 0) {
		const realEvents = mealPlans?.map((mealPlan: MealPlan) => {
			const firstRecipe = mealPlan.recipes[0];

			return {
				id: generateUniqueEventId(),
				title: mealPlan.mealType.toUpperCase(),
				mealPlanId: mealPlan?.id,
				recipe: {
					uuid: firstRecipe?.uuid,
					id: firstRecipe?.id,
					title: firstRecipe?.title,
					servings: parseInt(firstRecipe?.servings?.toString() ?? '0', 10),
					thumbnailUrl: firstRecipe?.instagramInformation?.thumbnail_url,
					mediaUrl: firstRecipe?.instagramInformation?.media_url,
				},
				recipes: mealPlan.recipes,
				start: mealPlan.date,
			};
		});

		const placeholderEvents = generatePlaceholderEvents(mealPlans);
		setEvents([...realEvents, ...placeholderEvents]);

		refetch();
	} else {
		const placeholderEvents = generatePlaceholderEvents([]);
		setEvents([...placeholderEvents]);
	}
};
