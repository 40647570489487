import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { PlannerIcon, RecipeIcon, SavedRecipeIcon } from './LinksIcons';

import settingsBlackIcon from '@/assets/svg//settings-black.svg';
import chefIcon from '@/assets/svg/cooking-hat-black.svg';
import searchIcon from '@/assets/svg/search-icon-black.svg';
import signOutBlackIcon from '@/assets/svg/signout-black.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { CdnImage } from '@/components/images/CdnImage';
import { User } from '@/context/interfaces';

interface DropDownProps {
	onLogout: () => void;
	user: User;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export const DropDown = ({ onLogout, user }: DropDownProps) => {
	const USER_PROFILE = `/user/me`;

	return (
		<Menu
			data-cy="dropdown-menu"
			as="div"
			className="relative inline-block text-left z-50"
		>
			<div>
				<Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-[28px]  py-[4px] text-sm font-semibold  text-black  border-none ">
					<CdnImage
						src={user?.profile_image?.src || userBlackIcon}
						alt="avatar"
						className="w-9 h-9 rounded-full object-cover bg-white"
						dataCy="dropdown-menu-profile-image"
					/>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black bg-slate-100 border-none">
					<div className="py-1">
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-profile-link"
									to={user.chef?.id ? '/chef/profile' : USER_PROFILE}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex items-center pl-4',
									)}
								>
									<img src={userBlackIcon} alt="userIcon" />
									<span className="block px-2 py-2 text-sm">Profile</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-trending-link"
									to={'/trending'}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex lg:hidden items-center pl-4',
									)}
								>
									<RecipeIcon scrolled={true} path="" />
									<span className="block px-2 py-2 text-sm">Trending</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-creators-link"
									to={'/chefs'}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex lg:hidden items-center pl-4',
									)}
								>
									<img src={chefIcon} alt="searchIcon" />
									<span className="block px-2 py-2 text-sm">Creators</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-saved-link"
									to={
										user.chef?.id
											? '/chef/profile?tab=saved'
											: `${USER_PROFILE}?tab=saved`
									}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex lg:hidden items-center pl-4',
									)}
								>
									<SavedRecipeIcon scrolled={true} path="" />
									<span className="block px-2 py-2 text-sm">Saved</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-planner-link"
									to={'/planner'}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex lg:hidden items-center pl-4',
									)}
								>
									<PlannerIcon scrolled={true} path="" />
									<span className="block px-2 py-2 text-sm">Planner</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-search-link"
									to={'/search'}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex md:hidden items-center pl-4',
									)}
								>
									<img src={searchIcon} alt="searchIcon" />
									<span className="block px-2 py-2 text-sm">Search</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<Link
									data-cy="dropdown-menu-settings-link"
									to={`/settings/me`}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex items-center pl-4',
									)}
								>
									<img src={settingsBlackIcon} alt="settings-icon" />
									<span className="block px-2 py-2 text-sm">Settings</span>
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									type="submit"
									onClick={onLogout}
									className={classNames(
										active ? 'bg-gray-300/50 ' : '',
										'flex items-center pl-4 w-full',
									)}
									data-cy="dropdown-menu-signout"
								>
									<img src={signOutBlackIcon} alt="sign-out-icon" />
									<span className="block px-2 py-2 text-left text-sm">
										Sign out
									</span>
								</button>
							)}
						</Menu.Item>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};
